import type { Provider } from '@supabase/gotrue-js/src/lib/types'

const production = process.env.NODE_ENV === 'production'

enum Themes {
  Light = 'light',
  Dark = 'dark',
}

const configuration = {
  site: {
    name: 'QA.tech',
    description: 'Stop writing tests. Focus on productivity.',
    themeColor: '#ffffff',
    themeColorDark: '#0a0a0a',
    siteUrl: process.env.NEXT_PUBLIC_SITE_URL,
    siteName: 'QA.tech',
    twitterHandle: '',
    githubHandle: '',
    language: 'en',
    locale: process.env.NEXT_PUBLIC_DEFAULT_LOCALE,
  },
  auth: {
    signupEnabled: true,
    signupWithCodeEnabled: true,
    signupCodes: [
      'haxlax',
      'get-started',
      'site-signup',
      'q-redirect', // Questionnaire redirect
      'ahZo8i',
      'So8rae',
      'Ien4li',
      'vimiY3',
      'chie6Y',
      'eiChi5',
      'bie6Ze',
      'Yoo2bo',
      'thie5T',
      'Agh7ai',
      'wie2Lu',
      'Eesoh6',
      'Laen7r',
      'Wae5pi',
      'Ee8Eet',
      'Yooz5a',
      'ahHo0u',
      'An3Koo',
      'aiXi8i',
      'quu7aB',
      'thahP4',
      'leiG9u',
      'chij7T',
      'Ie4kah',
    ],
    // ensure this is the same as your Supabase project. By default - it's true
    requireEmailConfirmation:
      process.env.NEXT_PUBLIC_REQUIRE_EMAIL_CONFIRMATION === 'true',
    // NB: Enable the providers below in the Supabase Console
    // in your production project
    providers: {
      emailPassword: true,
      phoneNumber: false,
      emailLink: false,
      oAuth: [] as Provider[],
    },
  },
  production,
  environment: process.env.NEXT_PUBLIC_ENVIRONMENT,
  enableThemeSwitcher: true,
  theme: Themes.Light,
  paths: {
    signIn: '/auth/sign-in',
    signUp: '/auth/sign-up?code=site-signup',
    signInMfa: '/auth/verify',
    onboarding: `/onboarding`,
    getStarted: `/get-started`,
    appPrefix: '/dashboard',
    appHome: '/dashboard',
    authCallback: '/auth/callback',
    settings: {
      profile: 'settings/profile',
      authentication: 'settings/profile/authentication',
      email: 'settings/profile/email',
      password: 'settings/profile/password',
    },
  },
  email: {
    host: process.env.EMAIL_HOST as string,
    port: 587,
    user: process.env.EMAIL_USER as string,
    password: process.env.EMAIL_PASSWORD as string,
    senderAddress: process.env.EMAIL_SENDER as string,
  },
  sentry: {
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  },
  cookies: {
    CURRENT_PROJECT_COOKIE_NAME: 'projectIdentifier',
    ORGANIZATION_ID: 'organizationId',
    IMPERSONATED_BY: 'impersonatedBy',
    SIDEBAR_STATE: 'sidebarState',
  },
  support: {
    links: {
      /** General support link to book a call with us */
      general: 'https://calendly.com/qa-tech-customers/qa-tech-support',
      /** After customer has subscribed, they can book a call with us */
      afterSignup: 'https://calendly.com/qa-tech-customers/after-signup-call',
      /** To change plan, customer can book a call with us, since we do not handle it automatically trough stripe yet */
      changePlan: 'https://calendly.com/qa-tech-customers/qa-tech-support',
      /** When the customers account is suspended */
      suspendedAccount: 'https://calendly.com/daniel-mauno/suspended-account',
    },
  },
  stripe: {
    products: [
      {
        name: 'Starter',
        badge: ``,
        description: 'Great for getting started with Autonomous QA',
        features: [
          'Price per Team',
          '1 Project Included',
          'Up to 20 Tests',
          'Up to 100 Test Runs / month',
          'Chat Support',
        ],
        plans: [
          {
            name: 'month per team',
            title: 'contributor',
            price: '$99',
            stripePriceId: process.env.NEXT_PUBLIC_STRIPE_STARTER_PRICE_ID,
          },
        ],
      },
      // {
      //   name: 'Pro',
      //   badge: `Most Popular`,
      //   recommended: true,
      //   description: 'Best for high-performing teams that deploy regularly',
      //   features: [
      //     'Price per Developer',
      //     '3 Projects Included',
      //     'Up to 50 Tests',
      //     'Up to 50 Test Runs / Month',
      //     'Chat Support',
      //   ],
      //   plans: [
      //     {
      //       name: 'month per dev',
      //       title: 'contributor',
      //       price: '$89',
      //       stripePriceId: process.env.NEXT_PUBLIC_STRIPE_PRO_PRICE_ID,
      //     },
      //   ],
      // },
      {
        name: 'Custom',
        description:
          'A custom plan tailored to your needs using our best models',
        recommended: true,
        badge: ``,
        features: [
          'Custom Integrations',
          'More Projects',
          'More than 20 tests',
          '100+ Test Runs / month',
          'Dedicated Support',
        ],
        plans: [
          {
            name: '',
            price: 'Contact Us',
            stripePriceId: '',
            label: `Contact Us`,
            href: `mailto:hi@qa.tech`,
          },
        ],
      },
    ],
  },
}

export default configuration
