import type { Theme } from '~/core/theming'

import {
  Button,
  Icon,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@repo/ui'
import { useMemo } from 'react'

import {
  isDarkSystemTheme,
  DARK_THEME_CLASSNAME,
  LIGHT_THEME_CLASSNAME,
  SYSTEM_THEME_CLASSNAME,
  useTheme,
} from '~/core/theming'
import Trans from '~/core/ui/Trans'

const DarkModeToggle = () => {
  const [theme, setTheme] = useTheme()
  const isDarkTheme = theme === DARK_THEME_CLASSNAME
  const isSystemTheme = theme === SYSTEM_THEME_CLASSNAME

  const icon = useMemo(() => {
    const shouldUseSystemDarkTheme = isSystemTheme && isDarkSystemTheme()

    if (isDarkTheme || shouldUseSystemDarkTheme) {
      return 'Moon'
    }

    return 'Sun'
  }, [isSystemTheme, isDarkTheme])

  return (
    <Select value={theme} onValueChange={(value) => setTheme(value as Theme)}>
      <SelectTrigger asChild>
        <Button
          data-cy={'dark-mode-toggle'}
          variant="ghost"
          size="icon"
          icon={icon}
          className={
            'flex items-center !rounded-full border-transparent transition-shadow' +
            ' !bg-transparent hover:shadow-xl dark:border-transparent' +
            ' dark:shadow-primary-500/50'
          }
        >
          <span hidden>
            <SelectValue />
          </span>
        </Button>
      </SelectTrigger>

      <SelectContent position={'popper'} sideOffset={5}>
        <SelectItem
          data-cy={'light-theme-button'}
          value={LIGHT_THEME_CLASSNAME}
        >
          <span className={'flex items-center space-x-2.5'}>
            <Icon name="Sun" size="small" />

            <span>
              <Trans i18nKey={'common:lightTheme'} />
            </span>
          </span>
        </SelectItem>

        <SelectItem data-cy={'dark-theme-button'} value={DARK_THEME_CLASSNAME}>
          <span className={'flex items-center space-x-2.5'}>
            <Icon name="Moon" size="small" />

            <span>
              <Trans i18nKey={'common:darkTheme'} />
            </span>
          </span>
        </SelectItem>

        <SelectItem
          data-cy={'system-theme-button'}
          value={SYSTEM_THEME_CLASSNAME}
        >
          <span className={'flex items-center space-x-2.5'}>
            <Icon name="ComputerDesktop" size="small" />

            <span>
              <Trans i18nKey={'common:systemTheme'} />
            </span>
          </span>
        </SelectItem>
      </SelectContent>
    </Select>
  )
}

export default DarkModeToggle
