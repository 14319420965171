'use client'

import classNames from 'clsx'
import { cva } from 'cva'
import Link from 'next/link'
import { usePathname } from 'next/navigation'
import { useContext } from 'react'

import isRouteActive from '~/core/generic/is-route-active'
import Trans from '~/core/ui/Trans'

import { NavigationMenuContext } from './NavigationMenuContext'

interface Link {
  path: string
  label?: string

  /**
   * @deprecated - Simply use {@link label}
   */
  i18n?: string
}

export const NavigationMenuItem: React.FCC<{
  link: Link
  depth?: number
  disabled?: boolean
  shallow?: boolean
  className?: string
}> = ({ link, disabled, shallow, depth, ...props }) => {
  const pathName = usePathname() ?? ''
  const active = isRouteActive(link.path, pathName, depth ?? 3)
  const menuProps = useContext(NavigationMenuContext)
  const label = link.label ?? link.i18n

  const itemClassName = getNavigationMenuItemClassBuilder()({
    active,
    ...menuProps,
  })

  const className = classNames(itemClassName, props.className ?? ``)

  if (disabled) {
    return (
      <span role={'link'} className={className}>
        <Trans i18nKey={label} defaults={label} />
      </span>
    )
  }

  return (
    <Link className={className} href={link.path} shallow={shallow ?? active}>
      <span className={'transition-transform duration-500'}>
        <Trans i18nKey={label} defaults={label} />
      </span>
      {active && <div className="bg-neutral-950 rounded-full h-5 w-[3px]" />}
    </Link>
  )
}

function getNavigationMenuItemClassBuilder() {
  return cva(
    [
      `p-2 flex items-center justify-between font-medium rounded-md text-sm transition-colors text-current`,
    ],
    {
      compoundVariants: [
        // not active - shared
        {
          active: false,
          className: `active:text-neutral-800 dark:active:text-white`,
        },
        // active - shared
        {
          active: true,
          className: `bg-neutral-200`,
        },
        // active - pill
        {
          active: true,
          pill: true,
          className: `bg-gray-50 dark:bg-dark-800`,
        },
        // not active - pill
        {
          active: false,
          pill: true,
          className: `hover:bg-neutral-200 active:bg-gray-200 dark:hover:bg-dark-800 dark:active:bg-dark-700`,
        },
        // not active - bordered
        {
          active: false,
          bordered: true,
          className: `hover:bg-gray-50 active:bg-gray-200 dark:active:bg-dark-700 dark:hover:bg-dark-800 transition-colors rounded-lg border-transparent`,
        },
        // active - bordered
        {
          active: true,
          bordered: true,
          className: `top-[0.4rem] border-b-[0.25rem] rounded-none border-primary-500 bg-transparent pb-[0.8rem]`,
        },
        // active - secondary
        {
          active: true,
          secondary: true,
          className: `bg-transparent font-semibold`,
        },
      ],
      variants: {
        active: {
          true: ``,
        },
        pill: {
          true: `py-2`,
        },
        bordered: {
          true: `relative h-10`,
        },
        secondary: {
          true: ``,
        },
      },
    },
  )
}
