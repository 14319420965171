/**
 * Database Tables
 *
 * Here we export our Database collection names so that we can reuse them
 * across both client and server libraries
 *
 */

export const USERS_TABLE = `users`
export const ORGANIZATIONS_TABLE = `organizations`
export const ORGANIZATIONS_INTERNAL_TABLE = `organizations_internal`
export const MEMBERSHIPS_TABLE = `memberships`
export const SUBSCRIPTIONS_TABLE = `subscriptions`
export const ORGANIZATIONS_SUBSCRIPTIONS_TABLE = `organizations_subscriptions`
export const PROJECTS_TABLE = `projects`
export const PROJECT_DEPENDENCIES_TABLE = `project_dependencies`
export const SCENARIOS_TABLE = `scenarios`
export const TEST_CASES_TABLE = `test_cases`

export const PROJECT_SCENARIOS_TABLE = `project_scenarios`
export const PROJECT_TEST_CASES_TABLE = `project_test_cases`

export const RUNS_TABLE = `runs`
export const RUN_SCENARIOS_TABLE = `run_scenarios`
export const RUN_TEST_CASES_TABLE = `run_test_cases`
export const RUN_SCHEDULES_TABLE = `run_schedules`

export const CONFIGS_TABLE = `configs`

export const CONNECTIONS_TABLE = 'connections'
export const INTEGRATIONS_TABLE = 'integrations'

export const ISSUES_TABLE = 'issues'
export const ISSUE_EVENTS_TABLE = 'issue_events'

export const AGREEMENTS_TABLE = 'agreements'
export const USER_AGREEMENTS_TABLE = 'user_agreements'

export const DETECTED_FEATURES_TABLE = 'test_detection_detected_features'
