'use client'

import { Button, Icon, If } from '@repo/ui'
import Link from 'next/link'

import DarkModeToggle from '~/components/DarkModeToggle'
import ProfileDropdown from '~/components/ProfileDropdown'
import configuration from '~/configuration'
import useUserSession from '~/core/hooks/use-user-session'
import { Container } from '~/core/ui/Container'
import { Logo } from '~/core/ui/Logo'

import SiteNavigation from './SiteNavigation'

const fixedClassName = `FixedHeader`

const SiteHeader: React.FCC<{
  fixed?: boolean
}> = ({ fixed }) => {
  const userSession = useUserSession()

  return (
    <div className={`w-full ${fixed ? fixedClassName : ''}`}>
      <Container>
        <div className="flex py-1.5 px-1 items-center border-b border-gray-50 dark:border-dark-800 justify-between">
          <div className={'w-4/12'}>
            <Logo href={userSession ? '/dashboard' : undefined} />
          </div>

          <div className={'w-4/12 justify-center hidden lg:flex'}>
            <SiteNavigation />
          </div>

          <div className={'flex flex-1 items-center justify-end space-x-4'}>
            <div className={'items-center flex'}>
              <If condition={configuration.enableThemeSwitcher}>
                <DarkModeToggle />
              </If>
            </div>

            <If condition={userSession} fallback={<AuthButtons />}>
              {(session) => <ProfileDropdown userSession={session} />}
            </If>

            <div className={'flex lg:hidden'}>
              <SiteNavigation />
            </div>
          </div>
        </div>
      </Container>
    </div>
  )
}

function AuthButtons() {
  return (
    <div className={'hidden space-x-2 lg:flex'}>
      <Link href={configuration.paths.signIn}>
        <Button>
          <span>Sign In</span>
        </Button>
      </Link>

      {configuration.auth.signupEnabled && (
        <Link href={configuration.paths.signUp}>
          <Button>
            <span className={'flex items-center space-x-2'}>
              <span>Sign Up</span>
              <Icon name="ArrowRight" size="small" />
            </span>
          </Button>
        </Link>
      )}
    </div>
  )
}

export default SiteHeader
