import type UserSession from '~/core/session/types/user-session'

import { createContext } from 'react'

export const UserSessionContext = createContext<{
  userSession: Maybe<UserSession>
  setUserSession: React.Dispatch<React.SetStateAction<Maybe<UserSession>>>
}>({
  userSession: undefined,
  setUserSession: (_) => _,
})
