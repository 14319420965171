import type Organization from '@packages/types/src/organization'

import { createContext } from 'react'

export type OrganizationContextPayload = Organization

const OrganizationContext = createContext<{
  organization: Maybe<OrganizationContextPayload>
  setOrganization: (organization: Maybe<OrganizationContextPayload>) => void
}>({
  organization: undefined,
  setOrganization: (_) => _,
})

export default OrganizationContext
