import type { Database } from '@repo/database-types'
import type { SupabaseClient } from '@supabase/supabase-js'

import { type ClassValue, clsx } from 'clsx'
import cronstrue from 'cronstrue'
import { twMerge } from 'tailwind-merge'

import { ORGANIZATIONS_TABLE } from './db-tables'

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

/**
 * @returns a new object with all undefined properties removed.
 */
export const omitUndefinedProps = <T extends Record<any, any>>(obj: T): T => {
  return Object.fromEntries(
    Object.entries(obj).filter(([_, value]) => value !== undefined),
  ) as any
}

export function getLogoFile(value: string | null | FileList | File) {
  if (!value) {
    return
  }

  if (typeof value === 'string') {
    return new File([], value)
  }

  if (value instanceof File) {
    return value
  }

  return value.item(0) ?? undefined
}

function getLogoName(fileName: string, organizationId: number) {
  const extension = fileName.split('.').pop()

  return `${organizationId}.${extension}`
}

/**
 * @description Upload file to Storage
 * @param client
 * @param organizationId
 * @param logo
 */
export async function uploadLogo({
  client,
  organizationId,
  logo,
}: {
  client: SupabaseClient<Database>
  organizationId: number
  logo: File
}) {
  const bytes = await logo.arrayBuffer()
  const bucket = client.storage.from('logos')
  const fileName = getLogoName(logo.name, organizationId)

  const result = await bucket.upload(fileName, bytes, {
    upsert: true,
    contentType: logo.type,
  })

  if (!result.error) {
    return bucket.getPublicUrl(fileName).data.publicUrl
  }

  throw result.error
}

/**
 * @description Upload file to Storage and save to organizations table
 * @param client
 * @param organizationId
 * @param logo
 */
export async function uploadLogoToOrganization({
  client,
  organizationUuid,
  logo,
}: {
  client: SupabaseClient<Database>
  organizationUuid: string
  logo: File
}) {
  const { data: org } = await client
    .from(ORGANIZATIONS_TABLE)
    .select('*')
    .eq('uuid', organizationUuid)
    .single()
    .throwOnError()

  if (!org) {
    throw new Error('Organization not found')
  }

  const organizationId = org.id

  const url = await uploadLogo({ client, organizationId, logo })

  await client
    .from(ORGANIZATIONS_TABLE)
    .update({ logo_url: url })
    .eq('id', organizationId)
    .throwOnError()

  return url
}

export function humanReadableCron(cron?: string) {
  if (!cron) {
    return 'Invalid cron string'
  }
  try {
    return cronstrue.toString(cron, { use24HourTimeFormat: true })
  } catch (error) {
    return 'Invalid cron'
  }
}

export const cronRegex =
  /(@(annually|yearly|monthly|weekly|daily|hourly|reboot))|(@every (\d+(ns|us|µs|ms|s|m|h))+)|((((\d+,)+\d+|(\d+(\/|-)\d+)|\d+|\*) ?){5,7})/g
